import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "bg-white shadow-bottom border-bottom d-flex justify-content-between px-6",
  style: { minHeight: '62px' }
}
const _hoisted_2 = {
  id: "launch-tour",
  class: "d-flex align-items-stretch flex-shrink-0 logo-nav"
}
const _hoisted_3 = { class: "content pt-4 d-flex flex-column flex-column-fluid container-fluid" }
const _hoisted_4 = { class: "d-flex flex-wrap flex-stack pb-7" }
const _hoisted_5 = { class: "d-flex flex-wrap align-items-center my-1" }
const _hoisted_6 = { class: "d-flex me-4 align-items-start flex-column" }
const _hoisted_7 = { class: "card-label fw-bolder text-gray-800" }
const _hoisted_8 = { class: "text-gray-400 mt-1 fw-bold fs-6" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "card mindmap-sample" }
const _hoisted_11 = { class: "card-header pt-3" }
const _hoisted_12 = { class: "card-title align-items-start flex-column" }
const _hoisted_13 = { class: "card-label fw-bolder text-gray-800" }
const _hoisted_14 = { class: "text-gray-400 mt-1 fw-bold fs-6" }
const _hoisted_15 = { class: "card-body" }
const _hoisted_16 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Logo = _resolveComponent("Logo")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_mindmap_demo_layout = _resolveComponent("mindmap-demo-layout")!

  return (_openBlock(), _createBlock(_component_mindmap_demo_layout, null, {
    default: _withCtx(() => [
      _createElementVNode("nav", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Logo)
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("h3", _hoisted_6, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t("mindmapSample.title")), 1),
              _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.$t("mindmapSample.subtitle")), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.samples, (sample, i) => {
            return (_openBlock(), _createElementBlock("div", {
              key: `${sample.name}-${i}`,
              class: "col-4"
            }, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("h3", _hoisted_12, [
                    _createElementVNode("span", _hoisted_13, _toDisplayString(sample.name), 1),
                    _createElementVNode("span", _hoisted_14, _toDisplayString(sample.nodesCount) + " " + _toDisplayString(sample.nodesCount > 1
                      ? _ctx.$t("mindmapSample.nodes")
                      : _ctx.$t("mindmapSample.node")), 1)
                  ]),
                  _createVNode(_component_router_link, {
                    tag: "button",
                    class: "btn btn-primary btn-sm",
                    to: {
                  name: 'mindmap-sample',
                  params: { sampleId: sample.routeParam },
                  query: { lang: _ctx.$i18n.locale },
                }
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t("mindmapSample.open")), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"])
                ]),
                _createElementVNode("div", _hoisted_15, [
                  _createElementVNode("img", {
                    src: sample.screenshot,
                    alt: ""
                  }, null, 8, _hoisted_16)
                ])
              ])
            ]))
          }), 128))
        ])
      ])
    ]),
    _: 1
  }))
}