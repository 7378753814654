
import { defineComponent, ref, watch } from "@vue/runtime-core";
import Logo from "@/components/layouts/mainLayout/header/Logo.vue";
import "v-onboarding/dist/style.css";
import MindmapDemoLayout from "@/components/layouts/mindmapDemoLayout/MindmapDemoLayout.vue";
import useTranslate from "@/core/composable/useTranslate";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { setModal } from "@/core/helpers/config";

export default defineComponent({
  name: "my-tour",
  components: {
    Logo,
    MindmapDemoLayout,
  },
  setup: () => {
    const { translate } = useTranslate();
    const i18n = useI18n();
    const route = useRoute();

    const samples = ref([
      {
        name: `${translate("mindmapSample.newProject")}`,
        nodesCount: 1,
        screenshot: "png/mindmap-samples/blank.png",
        routeParam: "default",
      },
      {
        name: "DevFactory",
        nodesCount: 28,
        screenshot: "png/mindmap-samples/dfp.png",
        routeParam: "devfactory",
      },
    ]);
    const setLang = () => {
      if (route.query?.lang == "en") {
        i18n.locale.value = "en";
      }
      if (route.query?.lang == "fr") {
        i18n.locale.value = "fr";
      }
    };
    watch(
      () => route.query.lang,
      async (newId) => {
        setLang();
      },
      { immediate: true }
    );

    setTimeout(() => {
      setModal("MindmapVideoModal");
    }, 1000);

    return { samples };
  },
});
